import { faArrowDown, faArrowUp, faArrowUpRightFromSquare, faFloppyDisk, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "./Dropdown";
import { deleteProject, getCardFromURL, pushCard, updateProject } from "../actions/fps";
import { allRepos, statusesToStrings } from "../util";
import { AffectedRepo } from "./AffectedRepo";
import Markdown from "react-markdown";

export const ProjectWindow = (props: ProjectWindowProps) => {
    const [projectName, setProjectName] = useState<string>('');
    const [trello, setTrello] = useState<string>('');
    const [cmf, setCmf] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [affected, setAffected] = useState<AffectedRepo[]>([]);

    const [selectedRepo, setSelectedRepo] = useState<AffectedRepo | undefined>();
    const [newRepoPopupVisible, setNewRepoPopupVisible] = useState<boolean>(false);
    const [confirmDeletePopupVisible, setConfirmDeletePopupVisible] = useState<boolean>(false);
    const [confirmPullPopupVisible, setConfirmPullPopupVisible] = useState<boolean>(false);
    const [confirmPushPopupVisible, setConfirmPushPopupVisible] = useState<boolean>(false);
    const [descriptionFocused, setDescriptionFocused] = useState<boolean>(false);

    const descRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (props.project === undefined) return;
        saveProjectState(props.project);
    }, [props.project]);

    useEffect(() => {
        if (descriptionFocused) descRef.current?.focus();
    }, [descriptionFocused]);

    const saveProjectState = (project: Project) => {
        setProjectName(project.name);
        setTrello(project.trello);
        setCmf(project.cmf);
        setStatus(project.status);
        setDesc(project.description);
        setAffected(project.affected);
        setSelectedRepo(project.affected.length > 0 ? project.affected[0] : undefined);
    };

    const saveToServer = (proj: Project | undefined) => {
        if (props.project === undefined) return;
        const updatedProject: Project = {
            id: proj?.id ?? props.project.id,
            name: proj?.name ?? projectName,
            status: proj?.status ?? status,
            cmf: proj?.cmf ?? cmf,
            trello: proj?.trello ?? trello,
            description: proj?.description ?? desc,
            affected: filterOutEmptyPRs(proj),
            nodes: [],
            checklist: [],
        };
        updateProject(updatedProject)
            .then(response => {
                if (!response.ok) throw new Error('Response returned with error code ' + response.status);
                sessionStorage.setItem('project', updatedProject.id);
                window.location.reload();
            })
            .catch(error => {
                console.error(error); // todo snackbar?
            });
    };

    const deleteProj = () => {
        if (!props.project) return;
        deleteProject(props.project.id)
            .then(response => {
                if (!response.ok) throw new Error('Response returned with error code ' + response.status);
                sessionStorage.removeItem('project');
                window.location.reload();
            })
            .catch(error => {
                console.error(error); // todo snackbar?
            });
    }

    const filterOutEmptyPRs = (proj: Project | undefined): AffectedRepo[] => {
        let repos: AffectedRepo[] = [];
        (proj?.affected ?? affected).forEach(repo => {
            let prs: PullRequest[] = repo.prs.filter(pr => pr.id.length > 0);
            repos.push({repo: repo.repo, branch: repo.branch, prs: prs})
        })
        return repos;
    }

    const updateAllRepos = (repo: AffectedRepo) => {
        let relevantRepos = affected.filter(a => a.repo === repo.repo && a.branch === repo.branch);
        if (relevantRepos.length === 0) {
            setAffected([...affected, repo]);
            return;
        }
        const relevantRepo = relevantRepos[0]; // should only ever have one element
        relevantRepo.prs = repo.prs; // nothing should need changing besides PRs
    };

    const pullFromTrello = () => {
        getCardFromURL(trello)
            .then(response => {
                if (!response.ok) throw new Error('Response returned with error code ' + response.status);
                return response.json();
            })
            .then(data => {
                const updatedProject: Project = {
                    id: props.project?.id ?? '',
                    name: data.name,
                    status: status,
                    cmf: data.cmf,
                    trello: trello,
                    description: data.desc,
                    affected: affected,
                    nodes: [],
                    checklist: [],
                };
                saveProjectState(updatedProject);
                saveToServer(updatedProject);
            })
            .catch(error => {
                console.error(error); // todo snackbar?
            });
    };

    const pushToTrello = () => {
        if (!props.project) return;
        pushCard(props.project)
            .then(response => {
                if (!response.ok) throw new Error('Response returned with error code ' + response.status);
            })
            .catch(error => {
                console.error(error); // todo snackbar?
            });
        setConfirmPushPopupVisible(false);
    };

    const NewRepoPopup = () => {
        const [dropdownRepo, setDropdownRepo] = useState<string>(allRepos[0]);
        const [branch, setBranch] = useState<string>('');
        const [error, setError] = useState<string | undefined>();

        const add = () => {
            if (branch.length === 0) {
                setError('Branch cannot be empty');
                return;
            }

            if (affected.filter(a => (a.repo === dropdownRepo && a.branch === branch)).length > 0) {
                setError('This repo already has this branch');
                return;
            }

            setAffected([...affected, {
                repo: dropdownRepo,
                branch: branch,
                prs: [],
            }]);
            setNewRepoPopupVisible(false);
        };

        return (
            <div className='modal-wrap'>
                <div className='modal-content'>
                    <div className='f-row my-2'>
                        <p className='mr-2'>Select a repository:</p>
                        <Dropdown options={allRepos} value={dropdownRepo} setOption={setDropdownRepo} width='w-64' height='h-72' />
                    </div>
                    <div className='f-row mb-2'>
                        <p className='mr-2'>Branch:</p>
                        <input className='px-1 w-full basic-border rounded-md' value={branch} onChange={e => setBranch(e.target.value)} />
                    </div>
                    <div className='text-red-500 mb-1 -mt-1'>{error}</div>
                    <div className='f-row justify-end'>
                        <div className='button-gray' onClick={() => setNewRepoPopupVisible(false)}>Cancel</div>
                        <div className='button-green ml-1' onClick={add}>Add</div>
                    </div>
                </div>
            </div>
        );
    };

    const ConfirmDeletePopup = () => {
        return (
            <div className='modal-wrap'>
                <div className='modal-content'>
                    <div className='mb-3'>Are you sure you want to delete this project?</div>
                    <div className='f-row justify-end'>
                        <div className='button-gray mr-2' onClick={() => setConfirmDeletePopupVisible(false)}>Cancel</div>
                        <div className='button-red' onClick={deleteProj}>Yes, Delete</div>
                    </div>
                </div>
            </div>
        );
    };

    const ConfirmPullPopup = () => {
        return (
            <div className='modal-wrap'>
                <div className='modal-content'>
                    <div className='text-xl mb-1'>
                        Pull from Trello?
                    </div>
                    <div className='mb-2 text-sm'>
                        This will update the name, description, and CMF if they have been changed in Trello.
                    </div>
                    <div className='f-row justify-end'>
                        <div className='button-gray mr-2' onClick={() => setConfirmPullPopupVisible(false)}>Cancel</div>
                        <div className='button-green' onClick={pullFromTrello}>Yes</div>
                    </div>
                </div>
            </div>
        );
    };

    const ConfirmPushPopup = () => {
        return (
            <div className='modal-wrap'>
                <div className='modal-content'>
                    {props.project?.trello ? (
                        <div>
                            <div className='text-xl mb-1'>
                                Push to Trello?
                            </div>
                            <div className='mb-2 text-sm'>
                                This will update the name, description, and CMF in Trello if they have been changed (and saved) here.
                            </div>
                            <div className='f-row justify-end'>
                                <div className='button-gray mr-2' onClick={() => setConfirmPushPopupVisible(false)}>Cancel</div>
                                <div className='button-green' onClick={pushToTrello}>Yes</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='text-xl mb-1'>
                                Unable to push to Trello
                            </div>
                            <div className='mb-2 text-sm'>
                                A valid Trello URL wasn't found (try adding one and saving)
                            </div>
                            <div className='f-row justify-end'>
                                <div className='button-gray mr-2' onClick={() => setConfirmPushPopupVisible(false)}>OK</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className='h-full w-full p-2'>
            {props.project?.name ? (
                <div className='f-col h-full'>
                    {/* ---------- Title Bar ---------- */}
                    <div className='custom-container mb-1'>
                        <div className='f-row justify-between'>
                            <div className='w-full'>
                                <b><input
                                    className='text-lg bg-transparent w-full px-0.5'
                                    value={projectName}
                                    onChange={e => setProjectName(e.target.value)}
                                /></b>
                            </div>
                            <div className='f-row'>
                                <div className='button-green mx-2' onClick={() => saveToServer(undefined)} title='Save'>
                                    <FontAwesomeIcon icon={faFloppyDisk} className='my-auto' />
                                </div>
                                <div className='button-green mr-2' onClick={() => setConfirmPullPopupVisible(true)} title='Pull from Trello'>
                                    <FontAwesomeIcon icon={faArrowDown} className='my-auto' />
                                </div>
                                <div className='button-green mr-2' onClick={() => setConfirmPushPopupVisible(true)} title='Push to Trello'>
                                    <FontAwesomeIcon icon={faArrowUp} className='my-auto' />
                                </div>
                                <div className='button-red' onClick={() => setConfirmDeletePopupVisible(true)} title='Delete'>
                                    <FontAwesomeIcon icon={faTrash} className='my-auto' />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ---------- Sub Header ---------- */}
                    <div className='custom-container f-row mb-1'>
                        <a href={trello} target='_blank' rel='noreferrer' className='mr-1 my-auto'>
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='my-auto' />
                        </a>
                        <input className='bg-transparent w-full px-0.5 mr-2' value={trello} onChange={e => setTrello(e.target.value)} />
                        <div className='f-row'>
                            <div className='mr-2 f-row items-center'><b>CMF:</b><input
                                type='text'
                                className='bg-white w-16 ml-1 basic-border rounded-md px-0.5'
                                value={cmf}
                                onChange={e => setCmf(e.target.value)}
                            /></div>
                            <div className='f-row items-center'>
                                <div className='mr-1'><b>Status:</b></div>
                                <Dropdown options={statusesToStrings()} value={status} setOption={setStatus} />
                            </div>
                        </div>
                    </div>

                    {/* ---------- Main Body ---------- */}
                    <div className='custom-container h-full f-row'>
                        <div className='f-col w-full'>

                            {/* ---------- Description ---------- */}
                            <div className='f-col h-2/4 basic-border mb-2 p-2'>
                                <div className='f-row justify-between items-center'>
                                    <b>Description / Notes</b>
                                    <div className='text-xs text-gray-500'>Markdown not supported (yet...)</div>
                                </div>
                                <div className={`h-full w-full ${descriptionFocused ? '': ''}`}>
                                    {descriptionFocused ? (
                                        <textarea 
                                            className='h-full w-full resize-none bg-transparent leading-4 py-1 px-0.5'
                                            value={desc}
                                            ref={descRef}
                                            onChange={e => setDesc(e.target.value)}
                                            onBlur={() => setDescriptionFocused(false)}
                                        />
                                    ) : (
                                        // <div className='h-full w-full cursor-text' onClick={() => setDescriptionFocused(true)}>
                                        //     <Markdown>{desc}</Markdown>
                                        // </div>
                                        <textarea 
                                            className='h-full w-full resize-none bg-transparent leading-4 py-1 px-0.5'
                                            value={desc}
                                            ref={descRef}
                                            onChange={e => setDesc(e.target.value)}
                                            onBlur={() => setDescriptionFocused(false)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='f-row h-2/4'>

                                {/* ---------- PRs ---------- */}
                                <div className='w-full basic-border p-2 f-col'>
                                    <b>Affected Repos | Pull Requests</b>
                                    <div className='f-row my-2'>
                                        <FontAwesomeIcon onClick={() => setNewRepoPopupVisible(true)} className='my-auto mr-2 !py-2 button-green' icon={faPlus} />
                                        {affected.map(repo => (
                                            <div
                                                key={repo.repo + repo.branch}
                                                onClick={() => {
                                                    console.log(affected)
                                                    setSelectedRepo(repo)
                                                }}
                                                className={`button basic-border mr-2 ${(selectedRepo?.repo === repo.repo && selectedRepo?.branch === repo.branch) ? 'bg-gray-300 basic-border' : 'bg-gray-400'}`}
                                            >
                                                {repo.repo} ({repo.branch})
                                            </div>
                                        ))}
                                    </div>
                                    <AffectedRepo repo={selectedRepo} updateAllRepos={updateAllRepos} />
                                </div>

                                {/* ---------- New Nodes ---------- */}
                                <div className='w-96 basic-border ml-2 p-2'>
                                    <b>New Nodes</b>
                                </div>
                            </div>
                        </div>

                        {/* ---------- Checklist ---------- */}
                        <div className='h-full w-96 basic-border ml-2 p-2'>
                            <b>Checklist</b>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='text-center my-auto text-2xl custom-container'>
                    <b>Select a project...</b>
                </div>
            )}
            {newRepoPopupVisible && <NewRepoPopup />}
            {confirmDeletePopupVisible && <ConfirmDeletePopup />}
            {confirmPullPopupVisible && <ConfirmPullPopup />}
            {confirmPushPopupVisible && <ConfirmPushPopup />}
        </div>
    );
}