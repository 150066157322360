import React, { useEffect, useState } from 'react'
import { SidePanel } from './components/SidePanel';
import { ProjectWindow } from './components/ProjectWindow';
import { getProjects } from './actions/fps';
import { getToken } from './util';
import { LoginScreen } from './components/Login';
import img1 from './assets/bgimg/img1.jpg';
import img2 from './assets/bgimg/img2.jpg';
import img3 from './assets/bgimg/img3.jpg';
import img4 from './assets/bgimg/img4.jpg';
import img5 from './assets/bgimg/img5.jpg';
import img6 from './assets/bgimg/img6.jpg';
import img7 from './assets/bgimg/img7.jpg';
import img8 from './assets/bgimg/img8.jpg';
import img9 from './assets/bgimg/img9.jpg';
import img10 from './assets/bgimg/img10.jpg';
import img11 from './assets/bgimg/img11.jpg';
import img12 from './assets/bgimg/img12.jpg';
import img13 from './assets/bgimg/img13.jpg';
import img14 from './assets/bgimg/img14.jpg';
import img15 from './assets/bgimg/img15.jpg';
import img16 from './assets/bgimg/img16.jpg';
import img17 from './assets/bgimg/img17.jpg';
import img18 from './assets/bgimg/img18.jpg';
import img19 from './assets/bgimg/img19.jpg';
import img20 from './assets/bgimg/img20.jpg';
import img21 from './assets/bgimg/img21.jpg';
import img22 from './assets/bgimg/img22.jpg';
import img23 from './assets/bgimg/img23.jpg';
import img24 from './assets/bgimg/img24.jpg';

const images = [
	img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12,
	img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24,
];

const favicons = [
	'adventure.ico',
	'alpha.ico',
	'clancy.ico',
	'currents.ico',
	'imaginal.ico',
	'nvm2.ico',
	'symmetry.ico',
	'tcd.ico',
	'ten.ico',
	'xny.ico',
];

const preloadImage = (src: string): Promise<void> => {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = src;
		img.onload = () => resolve();
	});
};

function App() {
	const [bgImg, setBgImg] = useState<string>(img1);
	const [projects, setProjects] = useState<Project[]>([]);
	const [selectedProject, setSelectedProject] = useState<Project>();

	useEffect(() => {
		if (!getToken()) return;

		getProjects()
			.then(response => {
				if (!response.ok) throw new Error('Response returned with error code ' + response.status);
				return response.json();
			})
			.then(data => {
				const currentProjects: Project[] = data as Project[];
				setProjects(currentProjects);
				const currentID = sessionStorage.getItem('project');
				if (currentID) {
					currentProjects.forEach(p => {
						if (p.id === currentID) setSelectedProject(p);
					});
				}
			})
			.catch(error => { // shouldn't happen
				console.log(error.message);
			});
	}, []);

	useEffect(() => {
		if (!getToken()) return;

		const changeBgImg = () => {
			const randomImage = images[Math.floor(Math.random() * images.length)];
			preloadImage(randomImage).then(() => {
				setBgImg(randomImage);
			});
		};

		const changeFavicon = () => {
			const randomFavicon = favicons[Math.floor(Math.random() * favicons.length)];
			const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.rel = 'icon';
			link.href = window.location.href + '/favicons/' + randomFavicon;
			document.getElementsByTagName('head')[0].appendChild(link);
		};
		changeFavicon(); // initial page load

		const intervalId = setInterval(() => {changeBgImg();changeFavicon()}, 60000);
		return () => clearInterval(intervalId);
	}, []);

	const addProject = (project: Project) => {
		setProjects(projects => [project, ...projects]);
	}

	return !getToken() ? (
		<LoginScreen />
	) : (
		<div className='f-col h-full w-full'>
			<div className='absolute -z-10 h-full w-full bg-cover bg-center' style={{
				backgroundImage: `url(${bgImg})`,
				transition: 'background-image 2s ease-in-out'
			}}></div>
			<div className='f-row h-full !brightness-100 p-2'>
				<SidePanel projects={projects} setProject={setSelectedProject} addProject={addProject} />
				<ProjectWindow project={selectedProject} />
			</div>
    	</div>
	);
}

export default App;
