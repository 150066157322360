import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { getCardFromURL, postProject } from '../actions/fps';
import { statuses } from '../util';
import { ExpandableList } from './ExpandableList';

export const SidePanel = (props: SidePanelProps) => {

    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [expandedSection, setExpandedSection] = useState<string | undefined>(undefined);

    const UrlPopup = () => {
        const [urlText, setUrlText] = useState<string>('');
        const [errorMessage, setErrorMessage] = useState<string>('');

        const reset = () => {
            setPopupVisible(false);
            setUrlText('');
            setErrorMessage('');
        }

        const clickOK = () => {
            getCardFromURL(urlText)
                .then(response => {
                    if (!response.ok) throw new Error('Response returned with error code ' + response.status);
                    return response.json();
                })
                .then(data => {
                    createProject(mapToProject(data));
                })
                .catch(error => {
                    setErrorMessage('Invalid URL');
                });
        }

        const clickSkip = () => {
            const emptyProject: Project = {
                id: crypto.randomUUID(),
                name: 'Untitled project',
                status: statuses[1].status,
                cmf: '',
                trello: '',
                description: '',
                affected: [],
                nodes: [],
                checklist: [],
            };
            createProject(emptyProject);
        }

        const createProject = (project: Project) => {
            postProject(project)
                .then(response => {
                    if (!response.ok) throw new Error('Response returned with error code ' + response.status);
                    sessionStorage.setItem('project', project.id);
                    window.location.reload();
                })
                .catch(error => {
                    setErrorMessage('Something went wrong...');
                });
        }

        const mapToProject = (data: any): Project => {
            return {
                id: data.id,
                name: data.name,
                status: '🟢 Current',
                cmf: data.cmf,
                trello: data.url,
                description: data.desc,
                affected: [],
                nodes: [],
                checklist: [],
            }
        }

        return (
            <div className='modal-wrap'>
                <div className='modal-content w-96'>
                    Enter Trello URL:
                    <input type='text' className='basic-border mb-2 px-1 rounded-md' onChange={e => setUrlText(e.target.value)} />
                    <div className='text-red-500 mb-2'>{errorMessage}</div>
                    <div className='f-row justify-between items-center'>
                        <div className='button bg-blue-300' onClick={clickSkip}>Skip <FontAwesomeIcon icon={faAngleRight} className='my-auto' /></div>
                        <div className='f-row'>
                            <div className='button-gray' onClick={reset}>Cancel</div>
                            <div className='button-green ml-1' onClick={clickOK}>OK</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='h-full w-80 flex-shrink-0 p-2 mr-2 f-col'>
            <div className='f-row justify-between items-center custom-container mb-1'>
                <div></div>
                <div className='text-lg'><b>Projects</b></div>
                <div className='button-green' onClick={() => setPopupVisible(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </div>
            {statuses.map((s: Status) => (
                <div key={s.status}><ExpandableList
                    status={s.status}
                    isExpanded={expandedSection === s.status}
                    setExpanded={setExpandedSection}
                    projects={props.projects.filter(p => p.status === s.status)}
                    setProject={props.setProject}
                /></div>
            ))}
            {popupVisible && <UrlPopup />}
        </div>
    );
}